import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image_2.png");

const section_1 = require("../../../assets/img/blogs/ms_teams_hrms_blog_1.png");
const section_2 = require("../../../assets/img/blogs/ms_teams_hrms_blog_2.png");
const section_3 = require("../../../assets/img/blogs/ms_teams_hrms_blog_3.png");
const section_4 = require("../../../assets/img/blogs/ms_teams_hrms_blog_4.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title=" MS Teams Bot for HR Support Transformation "
        description=" Learn how MS Teams Bot leads to HRMS transformation. Microsoft Teams Bot offers a scalable way to transform HR support."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={section_1}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            The Future of HRMs is Here with MS Teams Bots!
                          </h1>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          In every workspace today, everyone has one thing in
                          common ─ they use collaboration or communications
                          tools, and the most familiar one is Microsoft Teams.
                          What is more exciting is that organizations can use
                          <span className="font-section-normal-text-medium">
                            {" "}
                            Microsoft Teams Bots
                          </span>{" "}
                          to sync with their existing business tools or systems
                          and{" "}
                          <span className="font-section-normal-text-medium">
                            create event-triggered workflow automations.
                          </span>{" "}
                          The workflow automation ideally lessens massive
                          efforts that your employees otherwise need to put in
                          daily while accelerating their responses to certain
                          activities.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For example, Microsoft Teams Bots explicitly
                          complement Human Resource Management (HRM) processes
                          by automating many internal and external HR
                          operations. All you need is{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            create a chatbot for HRM tools, design workflows,
                            and combine them with self-service capabilities
                          </a>{" "}
                          via Microsoft Teams. This gives your HR teams and
                          employees an efficient way to manage usual queries by
                          themselves, which is fast and effective in optimizing
                          user productivity and saving operational costs.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In addition, Microsoft Teams Bots are highly likely to
                          address workplace inefficiency challenges by
                          encouraging quicker adoption of automation while
                          allowing organizations to maximize the power of AI and
                          machine learning algorithms at their best capacity.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          According to IDC,{" "}
                          <a href="https://www.infoworld.com/article/3688363/90-of-apac-enterprises-plan-to-deploy-ai-over-the-next-12-months-report.html?utm_date=20230302073521&utm_campaign=CIO%20US%20Analytics%20and%20Machine%20Learning%20Report&utm_content=Title%3A%2090%25%20of%20APAC%20enterprises%20plan%20to%20deploy%20AI%20over%20the%20next%2012%20months%3A%20Report&utm_term=CIO%20US%20Editorial%20Newsletters&utm_medium=email&utm_source=Adestra&huid=121362b2-07f9-4e0f-991d-05921a4819bc">
                            Artificial Intelligence is going to be mainstream in
                            the APAC regions by 2023.
                          </a>{" "}
                          The advancement is due largely to 88% of enterprises
                          already using AI or planning to harness this
                          technology.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          What could be a better opportunity to harness AI and
                          automation than today, when the advancement in
                          generative AI and LLMs is taking everyone by storm?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you want to learn more, below we explain how
                          <span className="font-section-normal-text-medium">
                            {" "}
                            Microsoft Teams Bots{" "}
                          </span>{" "}
                          can transform your HRM and help you stay current
                          amidst the massive digital acceleration with AI
                          proliferation and advancement.
                        </p>
                        <h2 class="font-section-sub-header-small-bold">
                          What are the benefits of Microsoft Teams Bots for your
                          HRM?
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          Microsoft Teams makes organization-wide communications
                          a breeze.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The channel-specific interactions are convenient for
                          sharing information and helping keep others informed
                          as and when needed.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          HR departments have long been leveraging the benefits
                          of creating multiple channels to interact and pass
                          information. However, channels are only good as long
                          as they stay relevant in addressing the challenges of
                          team members in real-time. Some limitations of
                          channels may impact the user experience in such
                          instances.
                        </p>
                        <ul
                          class="font-section-normal-text pl-3"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Someone needs to remain at their desks to answer
                            specific questions or offer help{" "}
                          </li>
                          <li>
                            Channels require monitoring 24x7, especially at a
                            time when work culture has evolved massively{" "}
                          </li>
                          <li>
                            Real-time responses or solutions, if not accessible
                            when needed, may interrupt work progress or delay
                            the entire work process{" "}
                          </li>
                          <li>
                            Power Automate enables to set up a Flow to send a
                            message automatically, but it can only be used as a
                            component to share information, and not a method to
                            provide a real-time solution for repetitive tasks{" "}
                          </li>
                        </ul>
                        <ul
                          class="font-section-normal-text pl-5"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Flow Bot sends messages automatically </li>
                          <li>
                            User-specific messages require manually triggered
                            action
                          </li>
                        </ul>

                        <p class="font-section-normal-text line-height-2">
                          To overcome these challenges with channel-specific
                          communications, Microsoft Teams Bots can offer great
                          respite and enable organizations to stay current with
                          the evolving work culture.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In addition, MS Teams Bots can be designed to work
                          inside Teams as an individual entity or to complement
                          specific one-to-one channels or group channels.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          For an HRM department, hr team members need to deal
                          with a massive burden of regular activities, which are
                          repetitive and laborious. Microsoft Teams Bots help
                          the HR department design{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/top-8-human-resource-tasks-to-automate-for-better-employee-experience">
                            HR workflow automation
                          </a>{" "}
                          and reduce the time to manage many of these processes.
                          As a result, an human resource team can empower their
                          work methods and offer instant help to their members
                          for an elevated employee experience.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Some fantastic benefits include 一
                        </p>
                        <h3 class=" font-section-sub-header-small-home">
                          Time-saving
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Automated workflows for human resources can help users
                          find solutions themselves. Be it queries regarding
                          leave management or PTO, automated HR workflows offer
                          instant help and accelerate the action without the
                          involvement of hr persons.
                        </p>
                        <h3 class=" font-section-sub-header-small-home">
                          Cost-effectiveness
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Usually, with the reduction of HR agent involvement,
                          the time to resolve an issue also reduces. It means it
                          takes less time to deflect user requests, which helps
                          reduce labor costs and costs related to missed
                          productivity in a scenario when your employees are
                          disengaged and unproductive. As a result, project
                          deadlines may extend, and business outcomes will be
                          delayed. Automated HR workflows you escalate with
                          Microsoft Teams Bots help you reduce the probability.
                        </p>
                        <h3 class=" font-section-sub-header-small-home">
                          Enhanced employee experience
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Employees are more engaged when they get information
                          and solution quickly.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            Microsoft teams bots for HR
                          </a>
                          allow you to create your workflows based on the unique
                          use cases and design your conversations using
                          authoring canvas. So, users can use FAQ-based
                          resources or knowledgebase to resolve their issues DIY
                          and get going easily.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div className="market_wrapper_page ">
                        <h2 className="font-section-sub-header-small-bold">
                          What are the current challenges faced by the HR
                          department?
                        </h2>
                        <img
                          src={section_1}
                          alt="HR challenges to be resolved using MS Teams Bots"
                          className="mb-3"
                        />
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Recruitment process</li>
                          <li>Reduced Operational Efficiency</li>
                          <li>Adapting to changes</li>
                          <li>Effective functioning of remote work</li>
                          <li>Creating a Positive Workplace Environment</li>
                          <li>Change management</li>
                          <li>Measuring HR effectiveness</li>
                          <li>Employee training programs</li>
                          <li>Retention of Employees</li>
                          <li>Performance Evaluation </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          MS Teams Bots: Innovative Approaches for Innovative
                          Workplaces
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          MS Teams Bots are efficient and powerful components
                          for HR departments as they enable them to use AI and
                          automation in assisting with workplace transitions.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          A well-built MS Team bot can amplify productivity like
                          no other tool in your business in multiple sectors
                          like IT support and service desk, and as an excellent
                          tool for workplace support.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          They can help with transitions and answer typical FAQs
                          and questions about corporate rules and procedures,
                          starting with the recruiting and onboarding processes.
                          Employees can benefit from step-by-step instructions
                          for their enrollments and obtaining information,
                          statistics, or evaluations that can be forwarded to
                          HR, analyzed, or polled.
                        </p>
                        <h2 className="font-section-sub-header-small-bold">
                          What is the return on investment for MS Teams bots in
                          the HR sector?
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          MS Teams bots guarantee a high return on investment.
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Compared to an HR staff, they can automate
                            repetitive operations in seconds rather than hours.{" "}
                          </li>
                          <li>
                            They also automate HR-employee interactions via a
                            variety of channels. If an employee needs assistance
                            with a business policy or wants to apply for leave,
                            they can request the bot and have it completed in
                            minutes, saving both their and your time.
                          </li>
                          <li>
                            When workers request training or presentations, bots
                            leverage the data to make these activities more
                            engaging and tailored, resulting in a superior user
                            experience.{" "}
                          </li>
                          <li>
                            Bots are very efficient since they work around the
                            clock and do not take vacations.
                          </li>
                          <li>
                            Employees can use these platforms at any time of day
                            and are not restricted to working hours.
                          </li>
                        </ul>
                        <h2 className="font-section-sub-header-small-bold">
                          What are the beneficial HRM use cases using MS Teams
                          Bots?
                        </h2>
                        <img
                          src={section_2}
                          alt="MS teams bots for HR process automation"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          HR experts can connect with the talent pool, match,
                          screen, and schedule interviews for matching applicant
                          profiles, payroll, and the right recruitment software
                          using MS Teams HR chatbots. Administrative duties can
                          be automated to save time and effort, allowing HR
                          experts to concentrate on other areas that may need
                          more attention.
                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          Recruitment
                        </h3>

                        <h4 className="font-section-normal-text-medium">
                          Minimize the time and cost of recruitment
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          Hiring automation can help expedite the recruiting
                          process and remove bottlenecks from the processes from
                          submitting to screening and then forwarding to the
                          next phase of selection procedures.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you create a bot and then{" "}
                          <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                            design app workflows through integration with your
                            ATS apps, such as BambooHR
                          </a>{" "}
                          or ZohoPeople and sync it within Microsoft, you can
                          pull up information and send the qualified candidates
                          to the HR bot directly to MS teams.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          It reduces the time for screening and the risk of
                          rejecting qualified candidates in cases where
                          screening and back-and-forth communications make the
                          processes labor-intensive and error-prone.
                        </p>
                        <h4 className="font-section-normal-text-medium">
                          How it works:
                        </h4>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Specify variables to detect the suitable candidates
                            with automated workflows
                          </li>
                          <li>
                            Workflow gets triggered with the candidate
                            submitting applications
                          </li>
                          <li>Screening takes place within the ATS platform</li>
                          <li>
                            Both rejected and qualified candidates will be
                            notified through automated messaging channels such
                            as emails
                          </li>
                          <li>
                            Messages get delivered the HR channels or MS Teams
                            Bots as well
                          </li>
                          <li>Hiring manager can take the procedure further</li>
                        </ul>
                        <h4 className="font-section-normal-text-medium">
                          Enhance onboarding experience{" "}
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          Avoid delaying the onboarding process for new hire and
                          rise the employee expectations using{" "}
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            employee onboarding automation processes.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With HR Bots sitting in your business comms channel MS
                          Teams, you can leverage the flexibility to automate
                          onboarding processes for a few repetitive tasks.
                        </p>
                        <img
                          src={section_3}
                          alt="Microsoft Teams Bots for HRM to enable onboarding automation"
                          className="mb-3"
                        />
                        <ul
                          class="font-section-normal-text pl-3"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Send an automated welcome message to a
                            #newonboarding channel from your HR bot
                          </li>
                          <li>
                            Schedule an invite with one of the stakeholders to
                            help the new hire know about company culture and
                            process
                          </li>
                          <li>
                            Arrange for a candid introduction session with
                            fellow team members by using automated meeting
                            invitation on the onboarding day
                          </li>
                          <li>
                            Automate provisioning of fundamental apps by sending
                            a list of credentials
                          </li>
                        </ul>
                        <ul
                          class="font-section-normal-text pl-5"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Provision email license </li>
                          <li>Provision apps license such as Slack or Teams</li>
                        </ul>
                        <h3 className="font-section-sub-header-small-home mt-3 mb-4">
                          Assistance to current workers
                        </h3>
                        <h4 className="font-section-normal-text-medium">
                          Ensure employee confidentiality
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/use-cases/leave-management-automation">
                            Leave management automation
                          </a>{" "}
                          effectively reduces the risk of violating the
                          confidentiality of employees with regards to
                          family-related matters or anything that involves
                          certain illnesses.
                        </p>
                        <img
                          src={section_4}
                          alt=" leave management automation with MS teams bots for HR"
                          className="mb-3"
                        />
                        <p class="font-section-normal-text line-height-2">
                          If your employees want to know some leave policy or
                          benefits of leaves, they can seamlessly ask a question
                          in an MS teams bot without making the conversation
                          public.
                        </p>
                      </div>

                      <div className="market_wrapper_page ">
                        <h3 className="font-section-sub-header-small">
                          Automation of HR tasks
                        </h3>

                        <h4 className="font-section-normal-text-medium">
                          Assessment of yearly performance
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          Annual performance review seems to be the most
                          burdensome assignment for HRs. HR spends days and
                          months conducting surveys, requesting workers to
                          submit the questionnaire, issuing reminders,
                          communicating, consolidating, and reviewing the
                          performance assessment! Not only is the data
                          inadequate most of the time, but the efficiency is
                          also poor since the staff is busy filling out these
                          questionnaires and forms! This is where HR chatbots
                          can assist! They may gather exact data from workers,
                          which saves time and eventually results in greater
                          productivity of the workforce and a pleased HR staff!
                        </p>
                        <h4 className="font-section-normal-text-medium">
                          Developing more operational efficiencies using
                          real-time statistics
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          An MS Teams bot can be utilized for more than simply
                          addressing employee queries. They can collect staff
                          data to make educated choices and build more efficient
                          procedures. For example, a chatbot could gather
                          real-time information to identify what type of queries
                          workers often ask. With this information, HR can
                          assess what’s going wrong and take the required
                          actions to remedy the situation!
                        </p>
                        <h4 className="font-section-normal-text-medium">
                          Goal-tracking
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          HR departments can utilize MS Teams bots to monitor
                          goal accomplishment for teams and individual workers.
                          This can be particularly handy for performance
                          reviews.
                        </p>
                        <h4 className="font-section-normal-text-medium">
                          Effective engagement of employees
                        </h4>
                        <p class="font-section-normal-text line-height-2">
                          Employee Engagement is a top priority for HR. Employee
                          engagement in the workplace is aimed at enhancing an
                          employee’s view of the organization and investing
                          genuine interest in the organizational objectives. HR
                          teams work hard to create a positive work environment
                          to enhance engagement and improve the employee
                          experience. MS Teams Chatbots play a significant role
                          by providing unending support and attending to
                          employee issues immediately.
                        </p>
                      </div>

                      <div className="market_wrapper_page ">
                        <h2 className="font-section-sub-header-small-bold">
                          How to create an HR bot in Microsoft Teams
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          A no-code chatbot platform makes it easy to create
                          your HR bot in Microsoft Teams. The{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            Workativ Assistant provides organizations with
                            extended flexibility that encompasses conversational
                            AI,
                          </a>{" "}
                          including NLP and machine learning algorithms, to help
                          create app workflow automations in a few steps.
                        </p>
                        <h3 className="font-section-sub-header-small-home">
                          Steps to creating your hr bot for Microsoft Teams with
                          Workativ
                        </h3>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            <a href="https://workativ.com/conversational-ai-platform/affordable-chatbot">
                              Sign up with Workativ assistant
                            </a>
                          </li>
                          <li>
                            <a
                              href=
                                "https://assistant.workativ.com/authentication/u/direct-signup"
                          
                            >
                              Download pre-built HR bot from the marketplace
                            </a>
                          </li>
                          <li>
                            Connect app workflows (for say, connect with
                            BambooHR or SAP SuccessFactors, etc.)
                          </li>
                          <li>
                            Create dialogs or conversations for multiple use
                            cases
                          </li>
                          <li>
                            Deploy an HR chatbot to Microsoft Teams and provide
                            24x7 support{" "}
                          </li>
                          <li>Test your bot by clicking on ‘Try Me’</li>
                          <li>Go live </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          If you need a comprehensive guide to building your HR
                          chatbot for Microsoft Teams,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/steps-to-build-hr-chatbot">
                            watch this video.
                          </a>
                        </p>
                        <h2 className="font-section-sub-header-small-bold">
                          Simplify your HRM with Workativ
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          MS Team bots can aid HR and IT departments by
                          decreasing the strain on these overburdened staff.
                          However, the finest bots must deliver more than
                          answers to FAQs.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          They must also provide solutions that ensure
                          flexibility, customization, and quick implementation.
                          You need a reliable bot that can do all this
                          effectively and efficiently. Workativ is a one-stop
                          solution for all your needs.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <a
                            href=
                              "https://assistant.workativ.com/authentication/u/direct-signup"
                          
                          >
                            "Learn more about Workativ Assistant by connecting
                            with our sales experts."
                          </a>
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h2>FAQs </h2>
                        <h3>
                          1. How do MS Teams Bots shape the future of HRMs?
                        </h3>
                        <p>
                          Traditionally, it involves manual processes for HR
                          operations and services. However, MS Teams bots can
                          integrate with HRM systems and directly access HR
                          workflows inside your MS Teams. So, employees can
                          autonomously manage functions such as onboarding and
                          offboarding while improving efficiency.
                        </p>
                        <h3>
                          2. What are the key advantages of incorporating MS
                          Teams Bots into HRMs?
                        </h3>
                        <p>
                          MS Teams bots efficiently automate HR workflows for
                          HRM systems. The workflow automation can boost
                          employee self-service while allowing HR staff to focus
                          on more critical issues and solve them efficiently.
                        </p>
                        <h3>
                          3. How do MS Teams Bots drive innovation in HR
                          management practices?
                        </h3>
                        <p>
                          MS Teams bots use AI and NLP to customize employee
                          responses according to the state of their problems. By
                          learning constantly about employee interactions, the
                          MS Teams bot can offer unique responses and help solve
                          problems, ultimately driving HR efficiency.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot ">
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-service-desk-transformation">
                                How MS Teams Bot leads to Service Desk
                                Transformation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide ">
                                {" "}
                                Slack Chatbot Guide for IT Helpdesk Automation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a
                        href=
                          "https://assistant.workativ.com/authentication/u/direct-signup"
                      
                      >
                        <img src={topImage} alt="slack chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free MS Teams Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
